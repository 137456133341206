/* eslint-disable react/function-component-definition */
import React, { useState } from "react"
import Seo from "../components/seo"
import { Link } from "gatsby"
// React Bootstrap
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
// Axios
import axios from "axios"
// Tag Manager
import { MdOutlineCheckBox } from "react-icons/md"
import Layout from "../components/layout"
import FadeIn from "../components/fade-in"


const GetStarted = () => {
  const [businessEmail, setBusinessEmail] = useState("")
  const [contactName, setContactName] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [country, setCountry] = useState("US")
  const [companyName, setCompanyName] = useState("")
  const [businessWebsite, setBusinessWebsite] = useState("")
  const [businessSize, setBusinessSize] = useState("Micro - Up to 50 Customers")
  const [preferredContactMethod, setPreferredContactMethod] = useState("")

  const [formConfirmed, setFormConfirmed] = useState("")
  const [formClicked, setFormClicked] = useState(false)

  const sendLeadForm = async e => {
    e.preventDefault()
    setFormClicked(true)
    const data = {
      businessEmail,
      contactName,
      contactNumber,
      country,
      companyName,
      businessWebsite,
      businessSize,
      preferredContactMethod,
    }

    const res = await axios.post("/api/lead_form/lead_info/", data, {
      withCredentials: true,
    })

    // If there's an error, set the error.
    if (res.status !== 200) {
      setFormConfirmed("error")
      return setFormClicked(false)
    } else {
      // set confirm message.
      return setFormConfirmed("success")
    }
  }

  return (
    <Layout>
        <Seo title="Get Started - Signaclass" description="Fill in this quick form to start talking to us, get more information or book a demo. 30 Day free trial for all new businesses."/>
      
      <div>
        <FadeIn delay={200} duration={1500}>
          <div className="shaded pt-5 pb-5" id="get-started-header" />
          <div>
            <Container className="pt-5">
              <Row className="align-items-center">
                <Col sm="12" lg="6" className="mb-5">
                  <div className="shadow p-4 white">
                    <Form onSubmit={sendLeadForm}>
                      <h2>
                        <strong>Your Info</strong>
                      </h2>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Business Email (Required)"
                          className="border"
                          required
                          value={businessEmail}
                          onChange={e => setBusinessEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formContactName">
                        <Form.Control
                          type="text"
                          placeholder="Contact Name (Required)"
                          className="border"
                          required
                          value={contactName}
                          onChange={e => setContactName(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Control
                          type="tel"
                          placeholder="Business Phone Number (Optional)"
                          className="border"
                          value={contactNumber}
                          onChange={e => setContactNumber(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formMethod">
                        <Form.Control
                          as="select"
                          className="border"
                          required
                          value={preferredContactMethod}
                          onChange={e =>
                            setPreferredContactMethod(e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Preferred Contact Method
                          </option>
                          <option value="Email" defaultValue>
                            Email
                          </option>
                          <option value="Telephone">Telephone</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                          Tell us if you prefer phone or email so we contact you
                          the right way :)
                        </Form.Text>
                      </Form.Group>

                      <h2>
                        <strong>Your Business</strong>
                      </h2>

                      <Form.Group className="mb-3" controlId="formContactName">
                        <Form.Control
                          type="text"
                          placeholder="Business Name (Required)"
                          className="border"
                          required
                          value={companyName}
                          onChange={e => setCompanyName(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formContactName">
                        <Form.Control
                          type="text"
                          placeholder="Business Website (Optional)"
                          className="border"
                          value={businessWebsite}
                          onChange={e => setBusinessWebsite(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formCountry">
                        <Form.Control
                          as="select"
                          className="border"
                          required
                          value={country}
                          onChange={e => setCountry(e.target.value)}
                        >
                          <option value="" disabled>
                            Choose Your Country
                          </option>
                          <option value="US" defaultValue>
                            United States
                          </option>
                          <option value="AF">Afghanistan</option>
                          <option value="AX">Åland Islands</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                          <option value="AG">Antigua and Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">
                            Bolivia, Plurinational State of
                          </option>
                          <option value="BQ">
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value="BA">Bosnia and Herzegovina</option>
                          <option value="BW">Botswana</option>
                          <option value="BV">Bouvet Island</option>
                          <option value="BR">Brazil</option>
                          <option value="IO">
                            British Indian Ocean Territory
                          </option>
                          <option value="BN">Brunei Darussalam</option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">Central African Republic</option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <option value="CX">Christmas Island</option>
                          <option value="CC">Cocos (Keeling) Islands</option>
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CG">Congo</option>
                          <option value="CD">
                            Congo, the Democratic Republic of the
                          </option>
                          <option value="CK">Cook Islands</option>
                          <option value="CR">Costa Rica</option>
                          <option value="CI">Côte d&apos;Ivoire</option>
                          <option value="HR">Croatia</option>
                          <option value="CU">Cuba</option>
                          <option value="CW">Curaçao</option>
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">Dominican Republic</option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">Equatorial Guinea</option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <option value="FK">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FR">France</option>
                          <option value="GF">French Guiana</option>
                          <option value="PF">French Polynesia</option>
                          <option value="TF">
                            French Southern Territories
                          </option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernsey</option>
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-Bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <option value="HM">
                            Heard Island and McDonald Islands
                          </option>
                          <option value="VA">
                            Holy See (Vatican City State)
                          </option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IR">Iran, Islamic Republic of</option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <option value="IM">Isle of Man</option>
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option value="KP">
                            Korea, Democratic People&apos;s Republic of
                          </option>
                          <option value="KR">Korea, Republic of</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">
                            Lao People&apos;s Democratic Republic
                          </option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option value="MK">
                            Macedonia, the former Yugoslav Republic of
                          </option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MQ">Martinique</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">
                            Micronesia, Federated States of
                          </option>
                          <option value="MD">Moldova, Republic of</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NZ">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <option value="NU">Niue</option>
                          <option value="NF">Norfolk Island</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papua New Guinea</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="RE">Réunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">Russian Federation</option>
                          <option value="RW">Rwanda</option>
                          <option value="BL">Saint Barthélemy</option>
                          <option value="SH">
                            Saint Helena, Ascension and Tristan da Cunha
                          </option>
                          <option value="KN">Saint Kitts and Nevis</option>
                          <option value="LC">Saint Lucia</option>
                          <option value="MF">Saint Martin (French part)</option>
                          <option value="PM">Saint Pierre and Miquelon</option>
                          <option value="VC">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <option value="ST">Sao Tome and Principe</option>
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <option value="SX">Sint Maarten (Dutch part)</option>
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <option value="SB">Solomon Islands</option>
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <option value="GS">
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value="SS">South Sudan</option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SD">Sudan</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard and Jan Mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="SY">Syrian Arab Republic</option>
                          <option value="TW">Taiwan, Province of China</option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">
                            Tanzania, United Republic of
                          </option>
                          <option value="TH">Thailand</option>
                          <option value="TL">Timor-Leste</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinidad and Tobago</option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <option value="TC">Turks and Caicos Islands</option>
                          <option value="TV">Tuvalu</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">United Arab Emirates</option>
                          <option value="GB">United Kingdom</option>
                          <option value="UM">
                            United States Minor Outlying Islands
                          </option>
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">
                            Venezuela, Bolivarian Republic of
                          </option>
                          <option value="VN">Viet Nam</option>
                          <option value="VG">Virgin Islands, British</option>
                          <option value="VI">Virgin Islands, U.S.</option>
                          <option value="WF">Wallis and Futuna</option>
                          <option value="EH">Western Sahara</option>
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                          Your country so we know your timezone and business
                          environment.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formMethod">
                        <Form.Control
                          as="select"
                          className="border"
                          required
                          value={businessSize}
                          onChange={e => setBusinessSize(e.target.value)}
                        >
                          <option value="" disabled>
                            Business Size
                          </option>
                          <option
                            value="Micro - Up to 50 Customers"
                            defaultValue
                          >
                            Micro - Up to 50 Customers
                          </option>
                          <option value="Small - 50 to 200 Customers">
                            Small - 50 to 200 Customers
                          </option>
                          <option value="Medium - 200 - 1000 Customers">
                            Medium - 200 - 1000 Customers
                          </option>
                          <option value="Large or Franchise - 1000+ Customers">
                            Large or Franchise - 1000+ Customers
                          </option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                          How many customers do you serve?
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="checkbox"
                          label={<PrivacyLabel />}
                          required
                        />
                      </Form.Group>
                      {formConfirmed === "success" && (
                        <Alert variant="success">
                          Success! We will contact you shortly.
                        </Alert>
                      )}
                      {formConfirmed === "error" && (
                        <Alert variant="danger" className="mt-3">
                          There was a problem. Please try again or open
                          livechat.
                        </Alert>
                      )}
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={formClicked}
                        size="lg"
                      >
                        Submit
                      </Button>
                      <p className="mt-3">
                        <em>
                          100% Privacy. We will never spam you or sell your
                          data!
                        </em>
                      </p>
                    </Form>
                  </div>
                </Col>
                <Col
                  sm="12"
                  lg="6"
                  className="text-lg-right flow-text text-center d-none d-lg-block"
                >
                  <h1 className="mb-3">
                    <strong>Let&apos;s get talking!</strong>
                  </h1>
                  <p>
                    <MdOutlineCheckBox /> Set up an account.
                  </p>
                  <p>
                    <MdOutlineCheckBox /> Book a demo.
                  </p>
                  <p>
                    <MdOutlineCheckBox /> Explore use cases for your business.
                  </p>
                  <p>
                    <MdOutlineCheckBox /> Discuss Signaclass in detail.
                  </p>
                </Col>
              </Row>
            </Container>
            <div id="about" className="pt-3 pb-5">
              <Container>
                <Row className="mt-4 mb-5">
                  <Col lg="6">
                    <div className="shadow p-4 mt-3">
                      <h4>
                        <strong>About Signaclass</strong>
                      </h4>
                      <p>
                        At Signaclass we develop software that saves activity
                        businesses administration time, saves them money and
                        enables them to reach more customers, in a better way.
                      </p>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="shadow p-4 mt-3">
                      <h4>
                        <strong>Support</strong>
                      </h4>
                      <p>
                        We&apos;re available to answer queries and provide
                        technical assistance from Monday to Friday. Contact
                        support using the chat widget or email{" "}
                        <a href="mailto:info@signaclass.com">
                          info@signaclass.com
                        </a>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </FadeIn>
      </div>
    </Layout>
  )
}

const PrivacyLabel = () => {
  return (
    <span className="ms-1">
      I have read and agree to your{" "}
      <Link to="/privacy-policy">
        Privacy Policy.
      </Link>
    </span>
  )
}

export default GetStarted
